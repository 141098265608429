import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ContentHeader from "../../components/unsereschule/contentHeader"
import Submenu from "../../components/unsereschule/submenu"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const AnsprechpartnerPage = ({data}) => (
  <Layout>
    <SEO title="Ansprechpartner"/>

    <ContentHeader headline="UNSERE SCHULE" subline={data.allSanitySiteSettings.nodes[0].motto}/>
    <section id="content_2_schule">

      <Submenu/>

      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{ color: "#007c34" }}>ANSPRECHPARTNER</span></h3>

        <h4 className="xsmall_sub">Schulleitung</h4>
        <p className="text">Frau Ulrike Lohöfener (Schulleiterin) & Maike Schneckener (Konrektorin)<br/>
          <span style={{ fontWeight: "bold" }}>Telefon:</span> 05732 3301
        </p>

        <br/>

        <h4 className="xsmall_sub">Sekretariat</h4>
        <p className="text"><span className="spanstyle">Sekretariat:</span> Frau Annika Moos-Kuban </p>
        <p className="text"><span className="spanstyle">&Ouml;ffnungszeiten:</span> Mo. + Mi. von 08:30 Uhr - 12:30 Uhr,
          Di. + Do. geschlossen, Fr. von 8:30 Uhr &ndash;
          12 Uhr </p>
        <p className="text"><span className="spanstyle">Telefon:</span> 05732 3301</p>
        <p className="text"><span className="spanstyle">Am besten erreichen Sie uns in den Pausenzeiten:</span></p>
        <p className="text">09.30 &ndash; 09.50 Uhr und 11.35 &ndash; 11.45 Uhr</p>

        <br/>
        <h4 className="xsmall_sub">Offener Ganztag</h4>
        <p className="text"><span style={{ fontWeight: "bold" }}>Ansprechpartnerin:</span> Frau Brennemann<br/>
          <span style={{ fontWeight: "bold" }}>Telefon:</span> 05732 9000267</p>
        <br/>
        <h4 className="xsmall_sub">Betreuung</h4>
        <p className="text"><span style={{ fontWeight: "bold" }}>Ansprechpartnerin:</span> Frau Kolbe<br/>
          <span style={{ fontWeight: "bold" }}>Telefon:</span> 05732 9008939</p>
        <br/>
        <h4 className="xsmall_sub">Hausmeister</h4>
        <p className="text">Herr Kr&auml;mer</p>
        <p className="text"></p>
        <br/>
        <h4 className="xsmall_sub">Lehrkräfte</h4>

        <p className="text">Im Schuljahr 2022 / 2023 werden bei uns 260 Schülerinnen und Schüler von
          16
          Lehrerinnen und Lehrern, einer Sozialpädagogin und einer Lehramtsanwärterin
          unterrichtet.
        </p>

      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default AnsprechpartnerPage
